@import url("https://fonts.googleapis.com/css2?family=Noor&display=swap");

@font-face {
  font-family: Noor;
  src: url(IBMPlexSansArabic-Regular.ttf);
}
.App,
body,
p {
  /* font-family: "Alexandria" !important; */
  font-family: Noor !important;
}

.main_button {
  background-color: #488b89 !important;
  border-radius: 12px !important;
  font-family: "Alexandria" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  /* padding: 10px !important; */
  text-transform: capitalize !important;
  color: white !important;
}
.no_borderButton {
  font-family: "Alexandria" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  /* padding: 10px !important; */
  text-transform: capitalize !important;
  color: #488b89 !important;
}
.container {
  background-color: #f3f6f6;
}

.white_container {
  background-color: white;
  padding: 20px;
}
.footer {
  color: white !important;
  background-image: url(./imgs/footerBack.png),
    linear-gradient(to left, rgba(0, 0, 0, 0.74), rgba(0, 0, 0, 0.74)) !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}
.footer_item {
  font-size: 14px !important;
  font-weight: 300 !important;
  color: white !important;
}
.footer_title {
  font-weight: 600 !important;
  color: white !important;
  font-size: 18px !important;
}
.navButton:hover {
  color: rgba(221, 98, 58, 1);
  border-bottom: 2px solid rgba(221, 98, 58, 1);
  border-radius: 0px;
}
.clients {
  background: url(../src/imgs/videosBack.png);
  background-repeat: no-repeat;
  background-position: right;
}
.client_card {
  width: 100%;
  border-radius: 12px;
  height: 280px;
  flex-direction: column;
  display: flex;
  /* position: relative; */
  top: 0px;
  background: none !important;
  padding: 0px !important;
}
.opacity {
  width: 94%;
  padding-right: 10.5px !important;
  padding-left: 5px !important;
  border-radius: 12px 12px 0px 0px;
  backdrop-filter: blur(5px);
  margin: auto;
  margin-top: 2px !important;
  /* position: absolute; */
}
.orange_container {
  background-color: rgba(221, 98, 58, 1);
  border-radius: 16px;
}
.orange_button {
  background-color: rgba(221, 98, 58, 1) !important;
  border-radius: 12px !important;
  font-family: "Alexandria" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  text-transform: capitalize !important;
  color: white !important;
}
.rectBack {
  background: url(../src/imgs/rectBack.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding: 0px !important;
}
.maskBack {
  background: url(../src/imgs/mask.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
}
.head {
  font-size: 60px !important;
}
.sec_img1 {
  width: 467px;
  height: 624px;
}
.sec_img2 {
  width: 406px;
  height: 492px;
}
.sec_img3 {
  width: 587px;
  height: 552px;
}
.sec_img4 {
  width: 276px;
  height: 260px;
}
.sec_img5 {
  width: 568px;
  height: 320px;
}
.sec_img6 {
  width: 697px;
  height: 452px;
}
.sec_img7 {
  width: 622px;
  height: 622px;
}
.sec_img8 {
  width: 336px;
  height: 376px;
}
.head_back {
  background: url(../src/imgs/shaps.png);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 90% 25%;
}

@media only screen and (max-width: 520px) {
  .head {
    font-size: 40px !important;
  }

  .clients {
    background-size: 80% 80% !important;
  }
  .sec_img2 {
    width: 206px;
    height: 292px;
  }
}

@media only screen and (max-width: 1120px) and (min-width: 900px) {
  .sec_img1 {
    width: 367px;
    height: 524px;
  }
  .sec_img2 {
    width: 306px;
    height: 392px;
  }
}
@media only screen and (max-width: 1120px) and (min-width: 579px) {
  .sec_img7 {
    width: 522px;
    height: 522px;
  }
}
@media only screen and (max-width: 579px) {
  .sec_img7 {
    width: 300px;
    height: 300px;
  }
}
@media only screen and (max-width: 1119px) and (min-width: 1050px) {
  .sec_img3 {
    width: 487px;
    height: 452px;
  }
  .sec_img4 {
    width: 220px;
    height: 210px;
  }
  .sec_img5 {
    width: 460px;
    height: 270px;
  }
}
@media only screen and (max-width: 1260px) and (min-width: 1119px) {
  .sec_img3 {
    width: 537px;
    height: 502px;
  }
  .sec_img4 {
    width: 241px;
    height: 230px;
  }
  .sec_img5 {
    width: 518px;
    height: 270px;
  }
}
@media only screen and (max-width: 1223px) and (min-width: 1050px) {
  .sec_img6 {
    width: 597px;
    height: 352px;
  }
}
@media only screen and (max-width: 1049px) and (min-width: 490px) {
  .sec_img3 {
    width: 387px;
    height: 352px;
  }
  .sec_img4 {
    width: 181px;
    height: 160px;
  }
  .sec_img5 {
    width: 380px;
    height: 220px;
  }
}
@media only screen and (max-width: 1051px) and (min-width: 553px) {
  .sec_img6 {
    width: 497px;
    height: 252px;
  }
}

@media only screen and (max-width: 524px) and (min-width: 424px) {
  .sec_img1 {
    width: 367px;
    height: 524px;
  }
}

@media only screen and (max-width: 553px) and (min-width: 453px) {
  .sec_img6 {
    width: 397px;
    height: 152px;
  }
}
@media only screen and (max-width: 423px) {
  .sec_img1 {
    width: 267px;
    height: 424px;
  }
}
@media only screen and (max-width: 490px) {
  .sec_img3 {
    width: 250px;
    height: 252px;
  }
  .sec_img4 {
    width: 115px;
    height: 100px;
  }
  .sec_img5 {
    width: 240px;
    height: 150px;
  }
}
@media only screen and (max-width: 453px) {
  .sec_img6 {
    width: 297px;
    height: 112px;
  }
}
@media only screen and (max-width: 400px) {
  .sec_img8 {
    width: 236px;
    height: 276px;
  }
}
